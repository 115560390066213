import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerSupportService } from 'src/app/services/customer-support.service';
import { ApiConstants } from 'src/app/utils/api-constants';
import { AppConstants } from 'src/app/utils/app-contants';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  public supportForm: FormGroup;
  public isFormSubmitted: boolean = false;
  public showButton : boolean = false;

  public industries = [
    "Energy, Utilities & Chemicals",
    "Financial Services",
    "Government",
    "Health Services",
    "High Tech",
    "Hospitality & Leisure",
    "Manufacturing",
    "Media & Entertainments",
    "Pharmaceuticals & Life Sciences",
    "Retail & Consumer Products",
    "Telecommunications",
    "Transportation",
    "Other",]

  constructor(
    private formBuilder: FormBuilder,
    public appConstants: AppConstants,
    private supportService: CustomerSupportService,
    public apiConstants: ApiConstants,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0)

    this.supportForm = this.formBuilder.group({
      firstname: new FormControl('',
        [Validators.required]),
      lastname: new FormControl('',
        [Validators.required]),
      companyname: new FormControl('',),
      city: new FormControl('',),
      state: new FormControl('',),
      country: new FormControl('',),
      industry: new FormControl('',),
      email: new FormControl('', [Validators.compose([
        Validators.required,
        Validators.pattern(this.appConstants.patterns.email)])]),
      comments: new FormControl('',),
    });

  }

  public sendEnquiry() {
    this.showButton = true;
    setTimeout(()=>{
      this.showButton = false;
    },5000)
    console.log("clicked ");
    
    if (this.supportForm.valid) {
      this.supportService.contactUs(this.supportForm.value).subscribe(result => {
        if (result.success) {
          //TODO: Handle success
          this.supportForm.reset();
          this.isFormSubmitted = true;
        } else {
          //TODO: Handle error
        }
      }, (error) => {
        //TODO: Handle error
      });
    }
  }

}
